import './index.scss'

import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Link } from 'gatsby'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('recipe-overflow')

const DURATION = 4000
const COUNT = 5
const TRANSITION = `transform ${DURATION}ms linear`

export default function RecipeOverflow({ recipes, title }) {
  const interval = useRef()
  const timer = useRef()
  const wrapperRef = useRef()
  const currentIndex = useRef(COUNT)
  const [transition, setTransition] = useState(TRANSITION)
  const [active, setActive] = useState(0)
  const [list, setList] = useState(
    recipes.filter((_, index) => index < currentIndex.current),
  )

  const updateActive = useCallback(() => {
    const nextIndex = currentIndex.current + 1
    currentIndex.current = nextIndex > recipes.length - 1 ? 0 : nextIndex

    setActive(active => active + 1)
    setList(list => [...list, recipes[currentIndex.current] || recipes[0]])
  }, [recipes])

  useEffect(() => {
    const checkVisibility = () => {
      if (document.hidden) {
        setTransition(null)
      } else {
        clearTimeout(timer.current)
        timer.current = setTimeout(() => {
          setTransition(TRANSITION)
          updateActive()
        }, 30)
      }
    }

    document.addEventListener('visibilitychange', checkVisibility)

    return () =>
      document.removeEventListener('visibilitychange', checkVisibility)
  }, [transition])

  useEffect(() => {
    clearTimeout(interval.current)
    setActive(1)

    interval.current = setInterval(updateActive, DURATION)

    return () => clearTimeout(interval.current)
  }, [recipes, interval, updateActive])

  // Reset once in a while, in case it goes on for too long and causes thing to get slow somehow
  useEffect(() => {
    if (active > 30) {
      currentIndex.current = COUNT
      setActive(0)
      setList(recipes.filter((_, index) => index < currentIndex.current))
      setTransition(null)
      clearTimeout(timer.current)

      timer.current = setTimeout(() => {
        setTransition(TRANSITION)
        updateActive()
      }, 30)
    }
    return () => clearTimeout(timer.current)
  }, [active, recipes, updateActive])

  return (
    <section {...bem('')} ref={wrapperRef}>
      <h2 {...bem('title')}>
        {title || 'Prøv en av oppskriftene våre i appen'}
      </h2>

      <div {...bem('wrapper')}>
        <ul
          {...bem('list')}
          style={{
            '--active': -active,
            transition,
          }}
        >
          {list.map(({ node: { title, id, image } }, index) => (
            <li key={id + index} {...bem('item')}>
              {active - 3 < index && image.url && (
                <img src={image.url} {...bem('image')} alt={title} />
              )}
            </li>
          ))}
        </ul>
      </div>

      <p {...bem('description')}>
        <Link to="/last-ned">Last ned appen</Link> og prøv over 1000 oppskrifter
        og kurs
      </p>
    </section>
  )
}

RecipeOverflow.propTypes = {
  title: T.any,
  recipes: T.array.isRequired,
}
