/* eslint-disable indent */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react'
import { graphql } from 'gatsby'
import T from 'prop-types'
import BlockContent from '@sanity/block-content-to-react'

import { Article } from '@components'
import SEO from '../components/SEO'
import Section from '../components/Section'
import Button from '../components/Button'
import RecipeOverflow from '../components/RecipeOverflow'
import Propaganda from '../components/Propaganda'
import RecipePreview from '../components/RecipePreview'

const BlockRenderer = type => props => {
  const style = props.node.style || 'normal'

  if (/^h\d/.test(style)) {
    const level = `h${style.replace(/[^\d]/g, '')}`
    return <Article.SubTitle type={level}>{props.children}</Article.SubTitle>
  }

  return <Article.Text type={type}>{props.children}</Article.Text>
}

const serializers = (type, products) => ({
  list: props => <Article.List type="tight">{props.children}</Article.List>,
  listItem: props => <Article.ListItem>{props.children}</Article.ListItem>,
  types: {
    block: BlockRenderer(type),
    textAndImages: props => (
      <Article.ImageSection
        images={props.node.images}
        type={props.node.imageWidth}
      >
        <BlockContent blocks={props.node.content} serializers={serializers()} />
      </Article.ImageSection>
    ),
    textAndImage: props => (
      <Article.ImageSection
        images={[props.node.image]}
        type={props.node.imageWidth}
      >
        <BlockContent blocks={props.node.content} serializers={serializers()} />
      </Article.ImageSection>
    ),
    quote: props => {
      return (
        <Article.Quote source={props.node.source}>
          {props.node.quote}
        </Article.Quote>
      )
    },
    images: props => <Article.Images images={props.node.images} />,
    cta: props => (
      <Propaganda products={products} {...props.node} fluid tight />
    ),
    recipe: ({
      node: {
        recipeTitle: title,
        recipePreamble: preamble,
        ...node
      }
    }) => <RecipePreview {...node} title={title} preamble={preamble} />,
  },
})

export default function Page({
  data: {
    foodsteps: { article, products },
  },
}) {
  const hasRecipe = article.node?.recipe?.node
  const articleProps = !hasRecipe
    ? {
        title: article.node.title,
        preamble: article.node.preamble,
        image: article.node?.image?.url,
        action: <Button label="Last ned appen" to="/last-ned" primary />,
      }
    : {}

  const recipes = article.node.recipes.edges

  return (
    <>
      <SEO
        title={article.node.title}
        description={article.node.preamble}
        image={article?.node?.image?.ogImageUrl}
      />

      <Section type="article" node="div">
        {hasRecipe && (
          <RecipePreview
            recipe={article.node.recipe}
            title={article.node.title || null}
            preamble={article.node.preamble || null}
            level={1}
          />
        )}
        <Article {...articleProps}>
          <BlockContent
            blocks={article.node.content}
            serializers={serializers('tight', products)}
          />

          {article.node.ctaTitle && article.node.ctaText && (
            <Propaganda
              products={products}
              title={article.node.ctaTitle}
              preamble={article.node.ctaText}
              fluid
            />
          )}

          {recipes.length > 0 && (
            <RecipeOverflow recipes={[...recipes, ...recipes]} />
          )}
        </Article>
      </Section>
    </>
  )
}

// Sånn ser recipe-queryen ut:
/*
recipe(slug: "potetomelett-aioli-tomat") {
  node {
    id
    slug
    title
    image {
      asset {
        metadata {
          palette {
            darkMuted {
              foreground
              background
            }
            darkVibrant {
              foreground
              background
            }
            dominant {
              foreground
              background
            }
            lightMuted {
              foreground
              background
            }
            lightVibrant {
              foreground
              background
            }
            muted {
              foreground
              background
            }
            vibrant {
              foreground
              background
            }
          }
        }
      }
      lqip
      url(width: 600)
    }
    duration
    preamble
    color
    quantitySettings {
      mode
      singleHeading
      doubleHeading
    }
    steps {
      id
      title
      video {
        asset {
          thumbnail
          url
        }
      }
      ingredients {
        id
        title
        quantity {
          two
          four
          six
          eight
        }
      }
    }
  }
}
*/

export const query = graphql`
  query ArticleTemplateQuery($slug: String!) {
    foodsteps {
      products {
        id
        title
        description
        includes
        variants {
          promotion {
            price
            title
            id
            description
            eligible
          }
          price
          months
        }
      }

      article(slug: $slug) {
        node {
          id
          slug
          title
          preamble
          ctaTitle
          ctaText
          image {
            url
            ogImageUrl: url(width: 1200, height: 630)
          }
          recipe {
            ...ArticleRecipe
          }
          content {
            _key
            _type
            ...PortableContent
          }
          recipes {
            edges {
              node {
                id
                slug
                title
                image {
                  url(width: 600, height: 800)
                }
              }
            }
          }
        }
      }
    }
  }

  fragment PortableContent on FS_PortableContent {
    _key
    _type
    ...PortableContentBlock
    ...PortableContentQuote
    ...PortableContentImages
    ...PortableContentTextAndImage
    ...PortableContentTextAndImages
    ...PortableContentCTA
    ...PortableContentRecipe
  }

  fragment ArticleRecipe on FS_RecipesEdge {
    node {
      id
      slug
      title
      image {
        asset {
          metadata {
            palette {
              darkMuted {
                foreground
                background
              }
              darkVibrant {
                foreground
                background
              }
              dominant {
                foreground
                background
              }
              lightMuted {
                foreground
                background
              }
              lightVibrant {
                foreground
                background
              }
              muted {
                foreground
                background
              }
              vibrant {
                foreground
                background
              }
            }
          }
        }
        lqip
        url(width: 600)
      }
      duration
      preamble
      color
      quantitySettings {
        mode
        singleHeading
        doubleHeading
      }
      steps {
        id
        title
        video {
          asset {
            thumbnail
            url
          }
        }
        ingredients {
          id
          title
          quantity {
            two
            four
            six
            eight
          }
        }
      }
    }
  }

  fragment PortableContentRecipe on FS_PortableContentRecipe {
    _key
    recipeTitle: title
    recipePreamble: preamble
    recipe {
      ...ArticleRecipe
    }
  }

  fragment PortableContentBlock on FS_PortableContentBlock {
    _key
    _type
    text
    style
    level
    listItem
    marks
    markDefs {
      _type
      _key
      ... on FS_PortableLinkMarkDefinition {
        href
      }
    }
    children {
      ... on FS_PortableContentBlock {
        _key
        _type
        text
        style
        level
        listItem
        marks
        markDefs {
          _type
          _key
          ... on FS_PortableLinkMarkDefinition {
            href
          }
        }
        children {
          ... on FS_PortableContentBlock {
            _key
            _type
            text
            style
            level
            listItem
            marks
            markDefs {
              _type
              _key
              ... on FS_PortableLinkMarkDefinition {
                href
              }
            }
          }
        }
      }
    }
  }

  fragment PortableContentQuote on FS_PortableContentQuote {
    quote
    source
  }

  fragment PortableContentCTA on FS_PortableContentCTA {
    title
    preamble
  }

  fragment PortableContentImages on FS_PortableContentImages {
    images {
      url
      alt
    }
  }

  fragment PortableContentTextAndImage on FS_PortableContentTextAndImage {
    imageWidth
    image {
      url
      alt
    }
    content {
      ...PortableContentBlock
    }
  }

  fragment PortableContentTextAndImages on FS_PortableContentTextAndImages {
    images {
      url
      alt
    }
    content {
      ...PortableContentBlock
    }
  }
`
