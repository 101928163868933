import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'
import { Link } from 'gatsby'

import { Icon } from '@components'

const bem = new BEMHelper('button')

export default function Button({
  to,
  className,
  children,
  primary,
  full,
  link,
  type,
  label,
  disabled,
  spaceless,
  icon,
  iconProps,
  small,
  left,
  arrow,
  dark,
  ...props
}) {
  const modifiers = {
    full,
    link,
    primary,
    spaceless,
    small,
    left,
    dark,
  }

  const content = (
    <>
      {icon && <Icon icon={icon} {...iconProps} {...bem('icon')} />}
      {children || label}
      {arrow && <Icon icon="chevron" {...bem('icon', 'after')} />}
    </>
  )

  if (
    typeof to === 'string' &&
    ((to.match(/(mailto|sms):/) || to.startsWith('http')))
  ) {
    return (
      <a href={to} {...bem('', modifiers, className)} {...props}>
        {content}
      </a>
    )
  }

  if (to) {
    return (
      <Link to={to} {...bem('', modifiers, className)} {...props}>
        {content}
      </Link>
    )
  }

  return (
    <button
      type={type}
      disabled={disabled}
      {...bem('', modifiers, className)}
      {...props}
    >
      {content}
    </button>
  )
}

Button.propTypes = {
  className: T.string,
  type: T.oneOf(['button', 'submit']).isRequired,
  to: T.oneOfType([T.string, T.object]),
  label: T.any,
  full: T.bool,
  link: T.bool,
  primary: T.bool,
  disabled: T.bool,
  onClick: T.func,
  children: T.any,
  spaceless: T.bool,
  icon: T.string,
  iconProps: T.object,
  small: T.bool,
  left: T.bool,
  arrow: T.bool,
  dark: T.bool,
}

Button.defaultProps = {
  type: 'button',
}
